/*-----------------------------------*\
  #main.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;700&display=swap');

:root {

    /**
     * colors
     */
  
    --violet-blue-crayola: hsla(234, 50%, 64%, 1);
    --dark-cornflower-blue_a7: hsla(214, 88%, 27%, 0.07);
    --white: hsla(0, 0%, 100%, 1);
    --white_a3: hsla(0, 0%, 100%, 0.03);
    --white_a8: hsla(0, 0%, 100%, 0.08);
    --white_a12: hsla(0, 0%, 100%, 0.12);
    --white_a70: hsla(0, 0%, 100%, 0.7);
    --cultured: hsla(220, 20%, 97%, 1);
    --lavender-web: hsla(233, 52%, 94%, 1);
    --cadet-blue-crayola: hsla(220, 12%, 70%, 1);
    --cadet-blue-crayola_a20: hsla(222, 23%, 71%, 0.2);
    --charcoal: hsla(218, 22%, 26%, 1);
    --raisin-black: hsla(216, 14%, 14%, 1);
    --light-gray: hsla(0, 0%, 79%, 1);
    --blue-crayola: hsla(219, 72%, 56%, 1);
    --black-coral: hsla(220, 12%, 43%, 1);
  
    /**
     * typography
     */
  
    --ff-manrope: 'Manrope', sans-serif;
  
    --fs-1: calc(2.7rem + 1.38vw);
    --fs-2: calc(2.6rem + .66vw);
    --fs-3: 2.2rem;
    --fs-4: 1.9rem;
    --fs-5: 1.8rem;
    --fs-6: 1.7rem;
    --fs-7: 1.5rem;
    --fs-8: 1.4rem;
    
    --fw-700: 700;
  
    /** 
     * spacing
     */
  
    --section-padding: 90px;
  
    /**
     * box shadow
     */
  
    --shadow-1: 0 0 20px hsla(216, 14%, 14%, 0.05);
    --shadow-2: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    --shadow-3: 0 0 1.25rem hsla(216, 14%, 14%, 0.04);
  
    /**
     * border radius
     */
  
    --radius-circle: 50%;
    --radius-pill: 100px;
    --radius-10: 10px;
    --radius-8: 8px;
    --radius-6: 6px;
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --transition-3: 0.3s ease-in-out;
  
  }
  
  
  
  
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
 
  img,
  span,
  input,
  button,
  ion-icon { display: block; }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  img { height: auto; }
  
  input,
  button {
    background: none;
    border: none;
    font: inherit;
  }
  
  input { width: 100%; }
  
  button { cursor: pointer; }
  
  ion-icon { pointer-events: none; }
  
  address { font-style: normal; }
  
  html {
    font-family: var(--ff-manrope);
    font-size: 10px;
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--white);
    color: var(--black-coral);
    font-size: 1.6rem;
    line-height: 1.7;
  }
  
  body.nav-active { overflow: hidden; }
  
  ::-webkit-scrollbar { width: 10px; }
  
  ::-webkit-scrollbar-track { background-color: hsl(0, 4%, 20%); }
  
  ::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 80%); }
  
  ::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }
  
  
  
  
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  .container { padding-inline: 16px; }
  
  .social-list {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .social-link {
    font-size: 2rem;
    transition: var(--transition-1);
  }
  
  .social-link:is(:hover, :focus-visible) { transform: translateY(-3px); }
  
  .section { padding-block: var(--section-padding); }
  
  .has-bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    color: var(--charcoal);
    font-weight: var(--fw-700);
    line-height: 1.3;
  }
  
  .h1 { font-size: var(--fs-1); }
  
  .h2 { font-size: var(--fs-2); }
  
  .h3 { font-size: var(--fs-3); }
  
  .h4 { font-size: var(--fs-4); }
  
  .h5 { font-size: var(--fs-6); }
  
  .btn {
    color: var(--white);
    font-size: var(--fs-6);
    font-weight: var(--fw-700);
    border: 2px solid var(--white);
    max-width: max-content;
    padding: 12px 28px;
    border-radius: var(--radius-pill);
    transition: var(--transition-1);
    will-change: transform;
  }
  
  .btn:is(:hover, :focus-visible) { transform: translateY(-4px); }
  
  .btn-primary,
  .btn-outline:is(:hover, :focus-visible) {
    background-color: var(--cadet-blue-crayola_a20);
    color: var(--charcoal);
  }
  
  .img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
    overflow: hidden;
  }
  
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .section-subtitle {
    font-size: var(--fs-7);
    text-transform: uppercase;
    color: var(--violet-blue-crayola);
    font-weight: var(--fw-700);
    margin-block-end: 16px;
  }
  
  .grid-list {
    display: grid;
    gap: 25px;
  }
  
  .w-100 { width: 100%; }
  
  
    /*-----------------------------------*\
    #Language
  \*-----------------------------------*/
  .language-switcher {
    display: flex;
    gap: 10px;
    margin-left: auto;
  }
  
  .language-switcher button {
    background: none;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 16px;
  }
  
  .language-switcher button:hover {
    text-decoration: underline;
  }
  
  
  /*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/
  .language-switcher {
    position: relative;
    display: inline-block;
  }
  
  .globe-icon {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .language-dropdown button {
    display: block;
    width: 100%;
    padding: 8px 16px;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
  }
  
  .language-dropdown button:hover {
    background-color: #f0f0f0;
  }
  
  .header .btn { display: none; }
  
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-block: 20px;
    box-shadow: var(--shadow-1);
    z-index: 4;
    background-image: url('../../public/images/hero-bg.png');
    background-size: cover;
  }
  
  .header.active {
    background-color: var(--white);
    position: fixed;
    animation: slideIn 0.5s ease forwards;
  }
  
  @keyframes slideIn {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
  }
  
  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  .header.active .logo-light,
  .header .logo-dark { display: none; }
  
  .header .logo-light,
  .header.active .logo-dark { display: block; }
  
  .nav-open-btn {
    font-size: 3.5rem;
    color: var(--charcoal);
  }
  
  .header.active .nav-open-btn { color: var(--charcoal); }
  
  .navbar {
    position: fixed;
    top: 0;
    left: -300px;
    background-color: var(--raisin-black);
    color: var(--white);
    max-width: 300px;
    width: 100%;
    height: 100vh;
    padding: 30px;
    padding-block-end: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 2;
    visibility: hidden;
    transition: var(--transition-3);    
  }

  .navbar-link-contact  {
    display: none; /* Hide by default */
    margin-top: auto;
  }
  
  .navbar.active {
    visibility: visible;
    transform: translateX(300px);
  }
  
  .navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-close-btn {
    background-color: var(--white_a8);
    color: var(--white);
    font-size: 2rem;
    padding: 6px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
  }
  
  .nav-close-btn ion-icon { --ionicon-stroke-width: 50px; }
  
  .nav-close-btn:is(:hover, :focus-visible) { background-color: var(--white_a12); }
  
  .navbar-list { 
    margin-block-end: auto;
  
  }
  
  
  
  
  .navbar-link {
    font-weight: var(--fw-700);
    padding-block: 6px;
  }
  
  .contact-link { transition: var(--transition-1); }
  
  .contact-link:is(:hover, :focus-visible) { color: var(--violet-blue-crayola); }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--raisin-black);
    pointer-events: none;
    opacity: 0;
    transition: var(--transition-1);
  }
  
  .overlay.active {
    pointer-events: all;
    opacity: 0.8;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #HERO
  \*-----------------------------------*/
  
  .hero {
    padding-block-start: calc(var(--section-padding) + 70px);
    text-align: center;
  }
  
  .hero .container {
    display: grid;
    gap: 70px;
  }
  
  .hero-title { color: var(--white); }
  
  .hero-text {
    font-size: var(--fs-5);
    color: var(--white);
    margin-block: 24px 36px;
  }
  .hero-websiteconstruction{
    color: brown;
    font-size: var(--fs-5);
    margin-block: 24px 36px;
  }
  
  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  
  .hero-slider,
  .hero-card { position: relative; }
  
  .hero-card .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    color: var(--charcoal);
    font-size: 2rem;
    padding: 25px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
    animation: pulse 2s ease infinite;
  }
  
  .hero-card .play-btn:is(:hover, :focus-visible) { color: var(--violet-blue-crayola); }
  
  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 var(--white_a70); }
    75% { box-shadow: 0 0 0 20px transparent; }
  }
  
  .hero .slider-inner {
    border-radius: var(--radius-10);
    overflow: hidden;
  }
  
  .hero .slider-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    transition: var(--transition-2);
  }
  
  .hero .slider-item {
    min-width: 100%;
    width: 100%;
    border-radius: var(--radius-10);
    overflow: hidden;
  }
  
  .hero .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--white_a70);
    color: var(--charcoal);
    font-size: 2rem;
    padding: 12px;
    border-radius: var(--radius-circle);
    transition: var(--transition-1);
  }
  
  .hero .slider-btn:is(:hover, :focus-visible) { background-color: var(--white); }
  
  .hero .slider-btn.prev { left: 20px; }
  
  .hero .slider-btn.next { right: 20px; }
  
  
  
  
  
  /*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/
  
  .service { text-align: center; }
  
  .service .section-title { margin-block-end: 50px; }
  
  .service-card {
    padding: 40px;
    box-shadow: var(--shadow-2);
    border-radius: var(--radius-6);
  }
  
  .service-card .card-icon {
    width: 60px;
    height: 60px;
    background-color: var(--violet-blue-crayola);
    display: grid;
    place-items: center;
    color: var(--white);
    font-size: 2.5rem;
    border-radius: var(--radius-circle);
    margin-inline: auto;
  }
  
  .service-card .card-icon ion-icon { --ionicon-stroke-width: 50px; }
  
  .service-card .card-title { margin-block: 16px 10px; }
  
  .service-card .btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-block-start: 10px;
    color: var(--violet-blue-crayola);
    font-weight: var(--fw-700);
    transition: var(--transition-1);
  }
  
  .service-card .btn-text:is(:hover, :focus-visible) { opacity: 0.9; }
  
  
  
  
  
  /*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/
  
  .about .container {
    display: grid;
    gap: 50px;
  }
  
  .about .section-title { margin-block-end: 35px; }
  
  .accordion-card .card-title { padding-block-end: 20px; }
  
  .accordion-btn {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .accordion-btn ion-icon {
    font-size: 1.5rem;
    color: var(--blue-crayola);
    transition: var(--transition-1);
  }
  
  .accordion-card.expanded .accordion-btn ion-icon {
    transform: rotate(0.5turn);
  }
  
  .accordion-btn .span { transition: var(--transition-1); }
  
  .accordion-btn:is(:hover, :focus-visible) .span,
  .accordion-card.expanded .accordion-btn .span { color: var(--violet-blue-crayola); }
  
  .accordion-content {
    padding-inline-start: 24px;
    max-height: 0;
    overflow: hidden;
  }
  
  .accordion-card.expanded .accordion-content {
    max-height: max-content;
    padding-block-end: 20px;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #FEATURES
  \*-----------------------------------*/
  
  .feature .container {
    display: grid;
    gap: 50px;
  }
  
  .feature .section-text { margin-block: 25px 30px; }
  
  .feature-list {
    display: grid;
    gap: 15px;
  }
  
  .feature-card {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .feature-card .card-icon {
    background-color: var(--lavender-web);
    font-size: 1.4rem;
    padding: 4px;
    border-radius: var(--radius-circle);
  }
  
  .feature-card .card-icon ion-icon { --ionicon-stroke-width: 40px; }
  
  
  
  
  
  /*-----------------------------------*\
    #STATS
  \*-----------------------------------*/
  
  .stats { background-image: linear-gradient(to bottom, var(--white) 50%, var(--cultured) 50%); }
  
  .stats-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 36px 24px;
    padding: 45px 30px;
    border-radius: var(--radius-8);
  }
  
  .stats-card .card-text > * { color: var(--white); }
  
  
  
  
  
  /*-----------------------------------*\
    #PROJECT
  \*-----------------------------------*/
  
  .project { background-color: var(--cultured); }
  
  .project :is(.section-subtitle, .section-title) { text-align: center; }
  
  .project .section-title { margin-block-end: 50px; }
  
  .project-card {
    background-color: var(--white);
    border-radius: var(--radius-8);
    overflow: hidden;
    box-shadow: var(--shadow-2);
    height: 100%;
  }
  
  .project-card .card-content { padding: 30px; }
  
  .project-card .card-title { transition: var(--transition-1); }
  
  .project-card .card-title:is(:hover, :focus-visible) { color: var(--violet-blue-crayola); }
  
  .project-card .card-text { margin-block: 16px 20px; }
  
  .project-card .card-meta-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .project-card .card-meta-item {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--cadet-blue-crayola);
    font-size: var(--fs-8);
  }
  
  
  
  
  
  /*-----------------------------------*\
    #CTA
  \*-----------------------------------*/
  
  .cta { background-color: var(--charcoal); }
  
  .cta .container {
    padding-block: 100px 60px;
    border-block-end: 1px solid var(--cadet-blue-crayola_a20);
  }
  
  .cta .section-title {
    color: var(--white);
    margin-block-end: 30px;
  }
  
  .cta .btn {
    background-color: var(--violet-blue-crayola);
    color: var(--white);
    border: none;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/
  
  .footer {
    background-color: var(--charcoal);
    padding-block: 60px 100px;
    color: var(--light-gray);
  }
  
  .footer-brand .footer-text { margin-block: 20px; }
  
  .footer .social-list { color: var(--white); }
  
  .footer-list-title {
    color: var(--white);
    margin-block-end: 16px;
  }
  
  .footer-link {
    padding-block: 4px;
    transition: var(--transition-1);
  }
  
  .footer-link:is(:hover, :focus-visible) { color: var(--violet-blue-crayola); }
  
  .input-wrapper {
    position: relative;
    margin-block-start: 25px;
  }
  
  .input-field {
    background-color: var(--white_a3);
    color: var(--light-gray);
    font-size: var(--fs-7);
    padding: 12px 16px;
    border: 1px solid var(--dark-cornflower-blue_a7);
    border-radius: var(--radius-6);
    box-shadow: var(--shadow-3);
    outline: none;
  }
  
  .input-field::placeholder { color: inherit; }
  
  .submit-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 var(--radius-6) var(--radius-6) 0;
    background-color: var(--violet-blue-crayola);
    color: var(--white);
    padding-inline: 24px;
    font-weight: var(--fw-700);
  }
  
  
  
  
  
  /*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/
  
  /**
   * responsive for large than 575px screen
   */
  
  @media (min-width: 575px) {
  
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 540px;
      width: 100%;
      margin-inline: auto;
    }

    
  
  
  
    /**
     * PROJECTS
     */
  
    .project-card .card-content { padding: 40px; }
  
  }
  
  
  @media (max-width: 767px){
    .navbar-list .navbar-link-contact {
      display: block; /* Display on small screens */
    }

    .navbar-link-contact .navbar-link {
      color: white; /* Change text color to white */
    }

    .navbar {
      justify-content: space-between;
    }
    
    .navbar-list {
      flex-grow: 1;
    }
  }
  
  
  /**
   * responsive for large than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * spacing
       */
  
      --section-padding: 120px;
  
    }

    
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 720px; }
  
    .grid-list { grid-template-columns: 1fr 1fr; }  
  
  
    /**
     * HEADER
     */
  
    .header .btn {
      display: block;
      margin-inline-start: auto;
      padding: 8px 20px;
    }
  
    .header.active .btn {
      background-color: var(--violet-blue-crayola);
      border-color: var(--violet-blue-crayola);
      color: var(--white);
    }


  
  
  
    /**
     * HERO
     */
  
    .hero-content {
      max-width: 85%;
      margin-inline: auto;
    }
  
    .hero-text { --fs-5: 2rem; } 
    
  
    /**
     * SERVICE
     */
  
    .service .section-title {
      max-width: 30ch;
      margin-inline: auto;
    }
  
  
  
    /**
     * ABOUT
     */
  
    .about .container {
      grid-template-columns: 1fr 0.7fr;
      align-items: center;
    }
  
  
  
    /**
     * FEATURE
     */
  
    .feature .container {
      grid-template-columns: 0.7fr 1fr;
      align-items: center;
    }
  
    .feature-banner { order: 1; }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 992px screen
   */
  
  @media (min-width: 992px) {
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 960px; }
  
  
  
    /**
     * HEADER
     */
  
    .nav-open-btn,
    .navbar > *:not(.navbar-list),
    .overlay { display: none; }
  
    .navbar,
    .navbar.active {
      all: unset;
      display: block;
      margin-inline: auto 24px;
    }
    
    
  
    .navbar-list {
      display: flex;
      gap: 30px;
    }
  
    .navbar-link {
      color: var(--charcoal);
      transition: var(--transition-1);
    }
  
    .navbar-link:is(:hover, :focus-visible) { opacity: 0.7; }
  
    .header.active .navbar-link { color: var(--charcoal); }
  
    .header.active .navbar-link:is(:hover, :focus-visible) {
      opacity: 1;
      color: var(--violet-blue-crayola);
    }
  
    .header .btn { margin-inline-start: 0; }
  
  
  
    /**
     * HERO
     */
  
    .hero {
      padding-block-start: calc(var(--section-padding) + 50px);
      text-align: left;
    }
  
    .hero .container {
      grid-template-columns: 1fr 0.8fr;
      align-items: center;
    }
  
    .hero-content {
      max-width: unset;
      margin-inline: 0;
    }
  
    .btn-wrapper { justify-content: flex-start; }
  
  
  
    /**
     * STATS
     */
  
    .stats-card { grid-template-columns: repeat(4, 1fr); }
  
  
  
    /**
     * PROJECT
     */
  
    .project .section-title {
      max-width: 32ch;
      margin-inline: auto;
    }
  
  
  
    /**
     * CTA
     */
  
    .cta .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .cta .btn { min-width: max-content; }
  
    .cta .section-title {
      max-width: 30ch;
      margin-block-end: 0;
    }
  
  
  
    /**
     * FOOTER
     */
  
    .footer .grid-list { grid-template-columns: repeat(4, 1fr); }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 1200px screen
   */
  
  @media (min-width: 1200px) {
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 1140px; }
  
  
  
    /**
     * HERO
     */
  
    .hero-text { padding-inline-end: 100px; }
  
  
  
    /**
     * SERVICE
     */
  
    .service .grid-list { grid-template-columns: repeat(4, 1fr); }
  
  
  
    /**
     * STATS
     */
  
    .stats .container { max-width: 70%; }
  
    .stats-card { padding: 60px; }
  
  
  
    /**
     * PROJECT
     */
  
    .project .grid-list { grid-template-columns: repeat(3, 1fr); }
  
  
  
    /**
     * FOOTER
     */
  
    address.footer-text {
      padding-inline-end: 100px;
      margin-block-end: 16px;
    }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 1400px screen
   */
  
  @media (min-width: 1400px) {
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 1320px; }
  
    
  
    /**
     * FEATURE
     */
  
    .feature-list { grid-template-columns: 1fr 1fr; }
  
  }