.paragraph{
    text-align:'left';
}
.contact-container {
    display: flex;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 26px;
}

.contact-me-container,
.contact-form-container {
    flex: 1;
    text-align: left;
    padding: 30px;
    margin: 5px;
    border: 5px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-form-container .form-group {
    margin-bottom: 15px; /* Adjust as needed */
}

.contact-form-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.contact-form-container input[type="text"],
.contact-form-container input[type="email"],
.contact-form-container textarea {
    width: 100%;
    height: 5%; /* Adjust height as needed */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: content-box;
    font-size: 16px;
    resize: none; /* Disable resizing */
    overflow-y: auto; /* Enable scrolling if content exceeds height */

}


.contact-form-container button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 26px;
    margin-top: 10px;
}

.contact-form-container button:hover {
    background-color: #3e8e41;
}

.contact-form-container .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

@media screen and (max-width: 769px) {
    .contact-container {
        display: flex;
        flex-direction: column;
    }
}