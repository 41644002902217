.skills-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial, sans-serif';
    color: #333;
  }
  
  .skills-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #444;
  }
  
  .skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .skill-group {
    flex: 1 1 calc(50% - 20px);
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  
  .skill-group h2 {
    font-size: 1.75em;
    border-bottom: 2px solid #007BFF;
    padding-bottom: 5px;
    color: #007BFF;
  }
  
  .skill-group ul {
    list-style-type: none;
    padding: 0;
  }
  
  .skill-group ul li {
    font-size: 1.25em;
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
  }
  
  .skill-group ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #007BFF;
    font-size: 1.5em;
    line-height: 1;
  }
  