body {
    /* background-image: url('../../public/images/archive/cool-background.png'); */ 
    background-size: cover;
    min-height: 100vh;
    background-color: #ffffff; 
}

.href {
  color: #000;
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}
.section-container{
  min-height: 100vh;
  padding: 0%;
}
.main-container {
  display: flex;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 26px;
}
.info-container {
  display: flex;
  flex-wrap: wrap;

  width: 45%;
  padding: 30px;
  margin: 5px;
  border: 5px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}
.sub-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.paragraph {
  font-size: 22px;
  line-height: 1.6;
  color: #555;
}
.normal-text {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}
a{
  color: #696363;
}





  

/* style.css */

/* Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px)  {
  .title {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
  }

  .sub-title {
    font-size: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
  }

  .paragraph {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }  

  .normal-text{
    font-size: 16px;
    line-height: 1.2;
  }
  
}

/* iPads, Tablets */
@media screen and (min-width: 481px) and (max-width: 768px){
}


/* laptops (tm 1024px) -> Desktops, large screens (tm 1200px) -> Extra large screens, TV */
@media screen and (min-width: 769px) {

}
